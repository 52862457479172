import "./index.css";
import React from "react";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { Drawer, message, Modal, Spin } from "antd";
import { baseUrl1, baseUrl2 } from "../../../../config";
import InputNumberModel from "../InputNumberModel";

const from1 = require("../../../../assets/images/mapImg/yongquan@2x.png");

const antIcon = (
  <LoadingOutlined style={{ fontSize: 16, color: "#ffffff" }} spin />
);

const Turret = (props) => {
  const { getBossStatus, getUserWallet, itemStatus, panImg, index, getBenlun } =
    props;
  const storeToken = useSelector((state) => state.App.token);
  const isZiDongDuiHuan = useSelector((state) => state.App.isZiDongDuiHuan);
  const userId = useSelector((state) => state.User.userId);

  const [item, setItem] = React.useState(props.item);
  const [level, setLevel] = React.useState(
    itemStatus?.user_turret_info?.level
      ? Number(itemStatus?.user_turret_info?.level) - 0
      : 0
  );
  // console.log(props?.gameState, "----------sssss");

  const [change, setChange] = React.useState(1);

  const inputNumberModelRef = React.useRef(null);

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // 购买食物
  const buy = async () => {
    if (props?.gameState !== "loading" && props?.gameState !== "inGame") {
      await message.warning("正在进行中～");
      return setVisible(false);
    }
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await axios({
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          user_id: userId,
          turret_id: item?.id,
          amount: change - 0,
          token: storeToken,
          convert: isZiDongDuiHuan ? isZiDongDuiHuan - 0 : 0,
        },
        url: `${baseUrl1}/api/v1/dinosaur_paradise/buyTurret`,
      });
      if (data?.status === 10000) {
        setLevel(Number(level) - 0 + Number(change) - 0);
        setVisible(false);
        getBossStatus && getBossStatus();
        getUserWallet && getUserWallet();
        message.success("投放成功", [0.3]);
        getBenlun(true, "inGame");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };

  // 弹窗关闭初始化数据
  React.useEffect(() => {
    if (!visible) {
      setChange(1);
    }
  }, [visible]);

  React.useEffect(() => {
    setItem(props.item);
    setLevel(
      itemStatus?.user_turret_info?.level
        ? Number(itemStatus?.user_turret_info?.level) - 0
        : 0
    );
  }, [props.item, itemStatus]);
  return (
    <>
      {/* 食物主体 */}
      {/* {item && (
        <div
          className="food"
          style={props.style}
          onClick={() => {
            if (props?.gameState !== "loading")
              return message.warning("派对正在进行中～");
            setVisible(true);
          }}
        >
          <img src={item?.gif_path} alt="" style={{ zIndex: 2 }} />
          <img
            src={from1}
            alt=""
            style={{ position: "absolute", bottom: -5, left: 2, width: 55 }}
          />
          <div
            className="food_info"
            style={{
              background:
                level > 0
                  ? "linear-gradient(90deg, #34B731, #1DCD94)"
                  : "linear-gradient(90deg, #d18f36, #ca772d)",
            }}
          >
            <span style={{ color: level > 0 ? "#fff" : "#fbf59b" }}>
              {level > 0 ? `${item?.name} x${level - 0}` : item?.name}
            </span>
          </div>
        </div>
      )} */}
      <div
        className="gift_box"
        onClick={
          () => {
            if (props?.gameState !== "loading")
              return message.warning("正在进行中～");
            setVisible(true);
          }
          // orderCkick(item)
        }
      >
        <div className="gift_img_box">
          {/* <img className="gift_img" src={item?.gif_path} alt="" /> */}
          <img className="gift_img" src={item?.icon} alt="" />
          <img
            className="guang_img"
            src={require("../../../../assets/Sushiimg/guangImg.png")}
            alt=""
          />
        </div>

        <div className="gift_text">
          <div className="text1">{/* 15倍 */}</div>
          <div className="text2">{item?.name}</div>
          {/* {index === 2 ? (
            <div className="text2">
              大大寿司味
            </div>
          ) : index >= 3 ? (
            <div className="text2">
              小小寿司
            </div>
          ) : (
            <div className="text2">
              没寿司
            </div>
          )} */}
        </div>
      </div>

      <Modal
        title={null}
        visible={visible}
        closable={false}
        footer={null}
        wrapClassName={"modal_box"}
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        className="ap_modal_food"
      >
        <div className="ap_modalon">
          <div
            className="ap_modal_header1"
            // style={{
            //   backgroundImage: `url(${require("../../../../assets/bg_popUp_top@2x.png")})`,
            // }}
          >
            {/* <span className="title">挑选食材</span>
            <img
              className="icon_close"
              onClick={() => {
                setVisible(false);
              }}
              src={require("../../../../assets/Sushiimg/close.png")}
              alt=""
            /> */}
          </div>
          <img
            className="close_img"
            src={require("../../../../assets/Sushiimg/close.png")}
            alt=""
            onClick={() => {
              setVisible(false);
            }}
          />
          <div className="ap_modal_body1">
            <img className="food_icon" src={item?.icon} alt="" />
            <span className="food_name">{item?.name}</span>
            <div className="food_stepper1">
              <img
                className="reduce"
                onClick={() => {
                  if (change - 0 === 0) return;
                  setChange(Math.max(0, change - 0 - 1));
                }}
                src={require("../../../../assets/Sushiimg/minus.png")}
                alt=""
              />
              <div className="food_stepper_value">
                <input
                  className="ap_input"
                  pattern="\d*"
                  type="number"
                  value={change}
                  maxLength={4}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, "");
                    if (e.target.value.length < 0) return;
                    if (e.target.value.length > 4) return;
                    // e.target.value = e.target.value - 0;
                    setChange(e.target.value);
                    // console.log(e.target.value, change);
                  }}
                />
              </div>

              <img
                className="add"
                onClick={() => {
                  if (change - 0 >= 9999) return;
                  setChange(change - 0 + 1);
                }}
                src={require("../../../../assets/Sushiimg/addImg.png")}
                alt=""
              />
            </div>
            <div className="food_stepper_box1">
              <div
                className="food_stepper_add"
                onClick={() => {
                  if (change - 0 >= 9999) return;
                  setChange(change - 0 + 10);
                }}
              >
                {/* <span className="label">+</span>
                <span className="value">10</span> */}
                <img
                  className="select_n"
                  src={require("../../../../assets/Sushiimg/+10.png")}
                  alt=""
                />
              </div>
              <div
                className="food_stepper_add"
                onClick={() => {
                  if (change - 0 >= 9999) return;
                  setChange(change - 0 + 20);
                }}
              >
                {/* <span className="label">+</span>
                <span className="value">20</span> */}
                <img
                  className="select_n"
                  src={require("../../../../assets/Sushiimg/+20.png")}
                  alt=""
                />
              </div>
              <div
                className="food_stepper_add"
                onClick={() => {
                  if (change - 0 >= 9999) return;
                  setChange(change - 0 + 50);
                }}
              >
                {/* <span className="label">+</span>
                <span className="value">50</span> */}
                <img
                  className="select_n"
                  src={require("../../../../assets/Sushiimg/+50.png")}
                  alt=""
                />
              </div>
              <div
                className="food_stepper_add"
                onClick={() => {
                  if (change - 0 >= 9999) return;
                  setChange(change - 0 + 100);
                }}
              >
                {/* <span className="label">+</span>
                <span className="value">100</span> */}
                <img
                  className="select_n"
                  src={require("../../../../assets/Sushiimg/+100.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="food_number1">
              <span className="label">已购买：</span>
              <span className="value">{Number(level) - 0}份</span>
            </div>
          </div>
          {/* <div className="ap_modal_footer1">
            <div className="right">
              <div
                className="btn"
                onClick={() => {
                  if (change - 0 === 0)
                    return message.warning("请选择投放数量~");
                  buy();
                }}
              >
                {(item?.pay_amount - 0) * (change - 0)}
                积分
              </div>
            </div>
          </div> */}
          <div
            className="submit_box"
            onClick={() => {
              if (change - 0 === 0) return message.warning("请选择投放数量~");
              buy();
            }}
          >
            <div className="jifen_box">
              {" "}
              {(item?.pay_amount - 0) * (change - 0)}
            </div>
          </div>
        </div>
      </Modal>
      {/* 输入购买食物个数 */}
      {/* <InputNumberModel
        ref={inputNumberModelRef}
        onChange={(count) => {
          setChange(Math.max(1, count - level));
        }}
      /> */}
    </>
  );
};

export default Turret;
